export const COLORS = [
	"none",
	"blue",
	"red",
	"green",
	"yellow",
	"pink",
	"purple",
	"indigo",
	"gray",
];
